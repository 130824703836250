import axiosReq from "utils/axiosConfig";
import { alertError, alertSuccess } from "./alert.service";

export const getUsers = async ({ queryKey }) => {
  const { orgIds } = queryKey.length > 1 ? queryKey[1] : {};
  try {
    const res = await axiosReq.get(`/api/user/users`, {
      params: {
        organizations: orgIds
      }
    });
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const getUser = async ({ queryKey }) => {
  const { userId } = queryKey.length > 1 ? queryKey[1] : {};
  try {
    const res = await axiosReq.get(`/api/user/users/${userId}`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const createUser = async ({values}) => {
  try {
    const res = await axiosReq.post(`/api/user/users/`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const updateUser = async ({values, userId}) => {
  try {
    const res = await axiosReq.put(`/api/user/users/${userId}`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const updatePassword = async ({values, userId}) => {
  try {
    const res = await axiosReq.put(`/api/user/users/password/${userId}`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const deleteUser = async ({deleteId}) => {
  try {
    const res = await axiosReq.delete(`/api/user/users/${deleteId}`);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const sendEmail = async (recipientId) => {
  try {
    // console.log(recipientId);
    const res = await axiosReq.post(`/api/user/users/sendemail/${recipientId}`);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}