import packageJson from "../../package.json";

export const getLocalStorageUser = () => {
  let user = "";
  let currentUser = localStorage.getItem("currentUser");
  if (currentUser) {
    currentUser = JSON.parse(currentUser);
    user = currentUser;
  }

  return user;
}

export const getAlias = (valueAlias) => {
  let alias = "";
  if (valueAlias) {
    valueAlias.map((name, index) => {
      if (index === valueAlias.length - 1)
        alias += name;
      else
        alias += `${name}, `;
      
      return false;
    });
  }

  return alias;
}

export const getUniqueArr = (arr, field) => {
  if (field) {
    const uniqueArray = arr.filter(function(item, index) {
      return arr.map(x => x[field]).indexOf(item[field]) === index;
    });
    return uniqueArray;
  }

  const uniqueArray = arr.filter(function(item, index) {
    return arr.indexOf(item) === index;
  });

  return uniqueArray;
}

export const getVersion = () => {
  return packageJson?.version ? packageJson.version : "1.0.0";
}

export const getListWorkday = () => {
  return [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ]
}